import React from "react"

import {
  AboutDan,
  Contact,
  ForTheFamily,
  Hero,
  TheApproach,
  Tesimonial,
} from "../components"
import { Layout, SEO } from "../components/layout"
import { Image } from "../components/helpers"

const IndexPage = () => (
  <Layout>
    <SEO />

    <Hero />

    <section className="pt-16 overflow-hidden bg-white lg:pt-24">
      <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-lg">
        <div className="relative">
          <h3 className="space-y-4 text-2xl leading-8 tracking-tight text-center text-gray-700 sm:space-y-6 font-body sm:text-4xl sm:leading-10">
            <p>
              Why can reading be so difficult <br className="sm:hidden" />
              and boring?
            </p>
            <p>Why do I lose my place? Or forget what’s been said?</p>
            <p>What is it that "good readers" understand that I don't?</p>
          </h3>
        </div>
      </div>
    </section>

    <section className="relative flex flex-col justify-center pt-16 overflow-hidden lg:pt-24">
      <Image
        className="h-80 sm:h-auto"
        src="Book stack FINAL.jpeg"
        alt="Classic Books"
      />

      <div className="absolute left-0 right-0 z-10 px-4 py-6 bg-opacity-75 sm:py-24 bg-pastel-light-blue sm:px-6 lg:px-8 ">
        <div className="relative max-w-xl mx-auto lg:max-w-screen-xl">
          <p className="text-xl leading-tight text-center sm:text-4xl font-typewriter">
            <span className="text-2xl font-bold sm:text-5xl">reading well</span>{" "}
            <br className="sm:hidden" />/ ‘rē• diŋ ‘wel / verb: reading with
            comprehension, know-how, speed, and satisfaction.
          </p>
        </div>
      </div>
    </section>

    <AboutDan />

    <section className="py-16 overflow-hidden sm:mt-16 bg-pastel-light-blue lg:py-20">
      <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <div className="relative">
          <p className="text-3xl leading-relaxed text-center sm:text-5xl font-typewriter">
            Let’s <span className="font-vintage">get</span> reading.{" "}
            <br className="sm:hidden" />
            It’s what I do.
          </p>
        </div>
      </div>
    </section>

    <TheApproach />
    <ForTheFamily />
    <Tesimonial />
    <Contact />
  </Layout>
)

export default IndexPage
