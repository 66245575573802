import React from "react"

export const Contact = () => {
  return (
    <div
      id="contact"
      className="mt-8 px-4 py-16 overflow-hidden bg-white sm:px-6 lg:px-8 lg:py-24"
    >
      <div className="relative max-w-xl mx-auto">
        <div className="z-10 text-center">
          <h2 className="text-3xl leading-9 tracking-tight text-gray-900 font-typewriter sm:text-5xl sm:leading-10">
            Let's make a plan.
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500 font-typewriter">
            Download a course{" "}
            <a
              href="/How to Literally Reading Course.pdf"
              className="text-primary-500 hover:text-primary-700 font-body underline transition-colors duration-200"
              target="_blank"
            >
              brochure
            </a>
            , and schedule your{" "}
            <a
              href="https://calendly.com/dan_literally"
              className="mt-2 px-4 py-2 uppercase rounded-full text-xl bg-primary-500 hover:bg-primary-700 transition-colors duration-200 text-white inline-block font-body font-medium"
              target="_blank"
              rel="noopener	nofollow"
            >
              free one-hour consultation
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
