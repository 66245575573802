import React from "react"

import { Image } from "./helpers"

export const Hero = () => {
  return (
    <div className="relative flex overflow-hidden md:max-h-screen bg-pastel-light-blue">
      <div className="w-full lg:w-1/2">
        <div className="relative z-10 bg-pastel-light-blue">
          <svg
            className="absolute inset-y-0 right-0 hidden w-48 min-h-screen transform translate-x-1/2 text-pastel-light-blue lg:block"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="max-w-screen-xl mx-auto">
            <div className="text-center lg:text-left">
              <div className="pt-8 lg:pb-32">
                <h1 className="px-4 text-2xl leading-10 tracking-tight text-gray-900 font-vintage sm:text-3xl sm:leading-none">
                  How to Literally
                  <span className="absolute font-sans text-xl">&trade;</span>
                </h1>
              </div>

              <div className="relative z-10 px-4 mx-auto mt-6 space-y-12 text-4xl leading-none text-center text-gray-900 sm:mt-16 font-typewriter sm:text-6xl md:max-w-3xl">
                <p>
                  Isn't it time
                  <br /> to learn how <br />
                  reading works?
                </p>
              </div>

              <div className="relative w-full h-full mt-10 overflow-hidden lg:my-8 lg:hidden lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <Image src="Dan-fireplace-mobile.jpg" alt="Dan Stowell" />
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="hidden lg:block lg:w-1/2">
        <Image src="Dan-fireplace.jpg" alt="Dan Stowell" />
      </div>
    </div>
  )
}
