import React from "react"
import { Image } from "./helpers"

export const Tesimonial = () => {
  return (
    <section className="py-12 overflow-hidden">
      <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="relative font-header">
          <blockquote className="mt-8">
            <div className="max-w-3xl mx-auto text-2xl font-medium leading-9 text-center text-gray-900">
              <p>
                &ldquo;Dan has changed the way our family reads. When my kids
                told me ‘I get it now,’ I wanted to learn what they had. I loved
                reading before, but I challenge myself more and now I remember
                what I've read. We all just appreciate it more… a very gifted
                instructor.&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <Image
                    className="w-10 h-10 mx-auto rounded-full"
                    src="Juliette Quote pic.jpg"
                    alt="Juliette Fumex"
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium leading-6 text-gray-900">
                    Juliette Fumex
                  </div>

                  <svg
                    className="hidden w-5 h-5 mx-1 text-primary-600 md:block"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium leading-6 text-gray-500">
                    Madison, Connecticut
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  )
}
