import React from "react"

export const ForTheFamily = () => {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h3 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 font-header sm:text-4xl sm:leading-10">
            ... for the whole family.
          </h3>
          <p className="max-w-xl mt-4 text-xl leading-7 text-gray-700 font-header lg:mx-auto">
            Focus on what matters most at every age.
          </p>
        </div>

        <div className="mt-10">
          <ul className="md:grid md:grid-cols-5">
            <li className="px-6 pt-10 pb-16 rounded-l-md md:mt-0 bg-gradient-50">
              <div>
                <h4 className="text-lg font-medium leading-6 text-gray-900 font-header">
                  Middle schoolers
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-700 font-body">
                  Develop a love of reading and an approach to longer, more
                  complicated material.
                </p>
              </div>
            </li>
            <li className="px-6 pt-10 pb-16 md:mt-0 bg-gradient-51">
              <div>
                <h4 className="text-lg font-medium leading-6 text-gray-900 font-header">
                  High schoolers
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-700 font-body">
                  Master pace and the high-level comprehension skills tested on
                  the SAT and ACT.
                </p>
              </div>
            </li>
            <li className="px-6 pt-10 pb-16 md:mt-0 bg-gradient-52">
              <div>
                <h4 className="text-lg font-medium leading-6 text-gray-900 font-header">
                  College students
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-700 font-body">
                  Learn strategies to tackle dense, academic material and heavy
                  coursework.
                </p>
              </div>
            </li>
            <li className="px-6 pt-10 pb-16 md:mt-0 bg-gradient-53">
              <div>
                <h4 className="text-lg font-medium leading-6 text-gray-900 font-header">
                  Parents
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-700 font-body">
                  Strengthen skills across all types of reading, and learn how
                  to best communicate them to your kids.
                </p>
              </div>
            </li>
            <li className="px-6 pt-10 pb-16 rounded-r-md md:mt-0 bg-gradient-54">
              <div>
                <h4 className="text-lg font-medium leading-6 text-gray-900 font-header">
                  Professionals
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-700 font-body">
                  Learn faster processes for synthesizing information and
                  identifying takeaways.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
