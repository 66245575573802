import React from "react"

import { Image } from "./helpers"

export const AboutDan = () => {
  return (
    <div id="about" className="pt-16 overflow-hidden bg-white lg:pt-24">
      <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <div className="relative">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 font-header sm:text-3xl sm:leading-9">
                Hi. I’m Dan, aka{" "}
                <a
                  href="https://www.linkedin.com/in/danstowell/"
                  target="_blank"
                  className="transition-colors duration-200 text-primary-600 hover:text-primary-800"
                >
                  {" "}
                  Mr. Literally
                </a>
                .
              </h4>
              <p className="mt-3 text-lg leading-7 text-gray-500 font-body">
                I’ve got a secret. I’m not a natural reader. But I do know how
                to read well. You might say I've literally mastered it.
              </p>
              <p className="mt-3 text-lg leading-7 text-gray-500 font-body">
                Most of my life, I was intimidated by reading. Words on a page
                didn’t make sense in my head. Reading anything “advanced” felt
                like a monumental task, and I avoided it as much as possible.
              </p>
              <p className="mt-3 text-lg leading-7 text-gray-500 font-body">
                Then I learned how reading worked. By studying reading skills
                and the science around reading, I discovered that reading
                mastery isn’t something we’re born with. But it can be taught.
                For a decade, I’ve been doing just that:{" "}
                <b>
                  passing on to students, young and old, the power of reading
                  well... for school, for standardized tests, for business, for
                  life
                </b>
                .
              </p>
              <p className="mt-3 text-lg leading-7 text-gray-500 font-body">
                With customized, one-on-one lessons, you will learn how to take
                any text and read it faster, with better comprehension, and with
                more enjoyment than you ever thought possible. Best of all,
                these skills literally improve with every page. Once you “get”
                reading, you read well for life.
              </p>
            </div>

            <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
              <div className="mx-auto overflow-hidden sm:rounded-full w-96 h-96">
                <Image className="relative " src="Dan.png" alt="Dan Stowell" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
